<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="6"
      >
        <v-switch
          color="primary"
          v-model="_flagAllCtmts"
          @change="selectAllCtmts($event)"
          label="Selecionar todas CTMTS"
        />
      </v-col>
      <v-col
        class="pb-0"
        cols="6"
      >
        <v-switch
          color="primary"
          v-model="_onlyEntitiesAneel"
          label="Utilizar somente entidades da ANEEL"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <v-textarea
          placeholder="Cole aqui os códigos de CTMT, preferencialmente um por linha"
          v-model="ctmtFilter"
          rows="10"
          no-resize
          clearable
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    ctmts: {
      type: Array,
      default: () => []
    },
    flagAllCtmts: {
      type: Boolean,
      default: () => false
    },
    displayOnlyEntitiesAneel: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    ctmtFilter: null
  }),
  computed: {
    _flagAllCtmts: {
      get() {
        return this.flagAllCtmts;
      },
      set(newValue) {
        this.$emit('update:flagAllCtmts', newValue);
      }
    },
    _onlyEntitiesAneel: {
      get() {
        return this.displayOnlyEntitiesAneel;
      },
      set(newValue) {
        this.$emit('update:displayOnlyEntitiesAneel', newValue);
      }
    }
  },
  methods: {
    selectAllCtmts(event) {
      event
        ? this.ctmtFilter = this.ctmts.map((ctmt) => ctmt.cod_id).join('\n') 
        : this.ctmtFilter = null; 
    },
    ctmtFilterToArray(ctmtFilterTxt) {
      let ctmtFilterArray = [];
      
      if (ctmtFilterTxt === null || ctmtFilterTxt === '') return ctmtFilterArray;
      
      ctmtFilterTxt = ctmtFilterTxt
      .replaceAll("'", '')
      .replaceAll('"', '')
      .replaceAll(',', '\n');
      ctmtFilterArray = ctmtFilterTxt
      .split('\n')
      .map((ctmt) => ctmt.trim())
      .filter((ctmt) => ctmt !== '');
      
      return ctmtFilterArray;
    }
  },
  watch: {
    ctmtFilter(newValue) {
      const ctmtFilterArray = this.ctmtFilterToArray(newValue);
      this.$emit('update:ctmtFilter', ctmtFilterArray);
      this.$emit('stepReleased', ctmtFilterArray.length);
    }
  }
};
</script>
